import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Import Link and useNavigate from react-router-dom
import './main.css';
import saar from './Group.png';
import WaitlistButton from './WaitlistButton';

const Main = () => {
  const [showText, setShowText] = useState(false); // State to manage the content of the waitlist button
  const navigate = useNavigate(); // useNavigate hook for programmatic navigation
  const [joined, setJoined] = useState(false);
  // Effect to trigger change after 5 seconds


  useEffect(() => {
    const timer = setTimeout(() => {
      setShowText(true);
    }, 5000);

    // Cleanup function to clear the timer
    return () => clearTimeout(timer);
  }, []);

  const handleJoinClick = () => {
    // Handle what happens when the user successfully joins the waitlist
    console.log('User joined the waitlist');
  };
  
  const handleWaitClick = () => {
    // Handle what happens when the waitlist button is clicked but the user hasn't joined yet
    console.log('Show waitlist form');
  };

  return (
    <div className="main-container">
      <div className="main-content">
        <img 
          className='saar-logo' 
          src={saar} 
          alt="Saar Logo" 
        
        />
        
        {/* When clicked, navigate to /login */}
        <button 
          className="main-button" 
          type="button"
       
          onClick={() => navigate('/login')}
        >
          Log In
        </button>
        
        <p className="waitlist-text" >
          Join the waitlist to get <br />a chance to pre-order
        </p>
        
        <h3 className="main-heading" >
          {/* Link to /signup */}
          <Link 
            to="/signup" 
            className="main-link" 
        
          >
            Sign Up
          </Link>
        </h3>
        
      
              <WaitlistButton handleJoinClick={handleJoinClick} handleWaitClick={handleWaitClick} />
     
      
      </div>
    </div>
  );
};

export default Main;
